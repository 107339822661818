import { Script } from 'gatsby';

const ThirdPartyResources = () => {
    const adobeUrl = process.env.GATSBY_LAUNCH_SCRIPT_URL;

    return (
        <>
            {/*  since we are showing loading spinner during A/B tests we can go back to async mode for all routes -- testing performance */}
            <Script src={adobeUrl} async />
            <Script src="https://app-ab41.marketo.com/js/forms2/js/forms2.min.js" async />
            <Script src="https://munchkin.marketo.net/munchkin.js" async />
            <Script
                src="https://js.sentry-cdn.com/2da199f1a8644a77a9973547ff9d44f7.min.js"
                crossOrigin="anonymous"
                async
            />
        </>
    );
};

export default ThirdPartyResources;
